export const ROUTER_DATA: MenuElement[] = require('./router-cofig.json');
export type MenuElement = {
    name: string;
    path: string;
    unLogin?: boolean;
    type: 'Bar' | 'Link' | 'None';
    element: string;
}

class menuElementMapping {
    mapping: Map<string, MenuElement>;
    menuBar: Map<string, MenuElement>

    constructor() {
        this.mapping = new Map<string, MenuElement>();
        this.menuBar = new Map<string, MenuElement>();
    }

    loadMenuElementMapping() {
        ROUTER_DATA.forEach(p => {
            if (this.mapping.has(p.path)) {
                throw new DOMException(p.path + " 路径重复！");
            } else {
                this.mapping.set(p.path, p)
            }
            if (p.type === "Bar") {
                this.menuBar.set(p.path, p)
            }
        })
    }

    getMenuBar(key: string): MenuElement | undefined {
        return this.menuBar.get(key)
    }

    getMapping(key: string): MenuElement | undefined {
        return this.mapping.get(key)
    }
}

const MenuElementMapping: menuElementMapping = new menuElementMapping()
MenuElementMapping.loadMenuElementMapping()
export default MenuElementMapping;
