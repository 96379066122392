// @ts-ignore
import md5 from "md5"
import {OpenWeb501} from "../common/utils";
import Constant from "@/config/global";

const loginUserCacheKey = "ls_mifeng"
const loginUserSecretKey = "ls_mifeng_token"
const loginUserTokenSecret = "asdfasdfqaewrf34trqrgjk$%@$^$%&^#%^!31gfdgasdada"

export class User {
    token: string | undefined;
    channelToken: string | undefined;
    channelCode: string | undefined;
    onlyInto: boolean | undefined;
    unInto: boolean | undefined;
    mobile: string | undefined;
    into: boolean | undefined;
    exists: boolean | undefined;
    osType: "F1" | "F2" | undefined; // 当app跳转网页时才会有 F1 安卓 F2 IOS
    autoKnow: boolean | undefined;
    captchaEnabled:boolean | undefined;
    constructor() {
        this.exists = false;
    }

    /**
     * 清除缓存
     */
    clear(): string {
        const channelCode = this.channelCode;
        this.exists = false;
        this.channelToken = undefined;
        this.osType = undefined;
        this.channelCode = undefined;
        this.onlyInto = undefined;
        this.unInto = undefined;
        this.mobile = undefined;
        this.into = undefined;
        localStorage.removeItem(loginUserCacheKey);
        localStorage.removeItem(loginUserSecretKey);
        return channelCode || "testCode";
    }

    /**
     * 同步缓存
     */
    storage() {
        const str = JSON.stringify(this)
        const sign = md5(str + "&" + loginUserTokenSecret)
        localStorage.setItem(loginUserCacheKey, str);
        localStorage.setItem(loginUserSecretKey, sign);

    }

    /**
     * 修改登录信息
     * @param data
     */
    save(data: User | {}) {
        // @ts-ignore
        Object.keys(data).forEach(p => this[p] = data[p])
    }

    /**
     * 修改登录信息并且保存
     * @param data
     */

    saveAndFlush(data: User | {}) {
        // @ts-ignore
        Object.keys(data).forEach(p => this[p] = data[p])
        if (this.mobile) {
            if (this.mobile.length === 11) {
                this.mobile = this.mobile.substring(0, 3) + "****" + this.mobile.substring(7, 11)
            }
        }
        this.storage()
    }

    /**
     * 懒加载获取User
     */
    sync(): User {
        if (this.exists) {
            return this;
        } else {
            this.reload()
            return this;
        }
    }

    /**
     * 重载User
     */
    reload() {
        const str = localStorage.getItem(loginUserCacheKey);
        if (str && str !== "") {
            const {
                token = undefined,
                channelToken = undefined,
                channelCode = undefined,
                onlyInto = undefined,
                into = undefined,
                unInto = undefined,
                osType = undefined,
                mobile = undefined,
            } = JSON.parse(str)
            const sign = md5(str + "&" + loginUserTokenSecret)
            if (sign === localStorage.getItem(loginUserSecretKey)) {
                this.token = token;
                this.channelToken = channelToken;
                this.channelCode = channelCode;
                this.onlyInto = onlyInto;
                this.into = into;
                this.unInto = unInto;
                this.mobile = mobile;
                this.osType = osType
                this.exists = true;
            } else {
                this.clear()
                OpenWeb501()
            }

        }
    }

    trigger(type: "OUT" | "INDEX" | "IDENTITY" | "MY" | "PRODUCT") {
        let path = window.location.origin + window.location.pathname;
        let param = new URLSearchParams(window.location.search);
        if (this.osType) {
            let addr = Constant.APP[this.osType]
            let url = addr[type]
            if (!param.has(addr.Alias)) {
                param.set(addr.Alias, url)
            }
            window.location.href = path + "?" + param.toString()
        }
    }
}

const LoginUser: User = new User().sync();
export default LoginUser;

