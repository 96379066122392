import "./error.less"
import {useMemo} from "react";

export default function Error501() {

    const urlCode = useMemo(() => {
        const param = new URLSearchParams(window.location.search);
        return param.get("code")
    }, [])
    return <>
        <div className={"error"}>
            <img src={require('./img/404.png')} alt=""/>
            <span>渠道信息不正确或登录信息错误 : {urlCode}</span>
        </div>
    </>
}
