import "./error.less"

export default function Error404() {

    return <>
        <div className={"error"}>
                <img src={require('./img/404.png')} alt="" />
                <span>页面走丢了</span>
        </div>
    </>
}
