import {Badge, TabBar} from 'antd-mobile'
import {AppOutline, AppstoreOutline, UserOutline} from 'antd-mobile-icons'
import {useEffect, useMemo, useState} from "react";
import {ElementChildrenProps} from "@/component/type";
import LoginUser from "@/store/loginUser";

const tabs = [
    {
        key: '/index',
        title: '首页',
        icon: <AppOutline/>,
        badge: Badge.dot,
    }, {
        key: '/whole',
        title: '全部',
        icon: <AppstoreOutline/>,
        badge: Badge.dot,
    },
    {
        key: '/my',
        title: '我的',
        icon: <UserOutline/>,
    },
]


export default function ITabPage(props: ElementChildrenProps) {
    const {children, element, history} = props;

    const [selectActive, setSelectActive] = useState("/")
    useEffect(() => {
        setSelectActive(element.path)
    }, [element.path])

    const itemChildren = useMemo(() => {
        return tabs.map(item => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title}/>
        ))
    }, [])

    let os = useMemo(() => {
        const params = new URLSearchParams(props.location.search)
        const cid = params.get("cid");
        if (cid) {
            LoginUser.saveAndFlush({token: "cid"})
        }
        return !!LoginUser.osType;
    }, [props.location.search, LoginUser])

    return <>
        <div className={"app"}>
            <div className={"body"}>
                {children}
            </div>

            {
                os ? <></> : <TabBar className={"bottom"} safeArea activeKey={selectActive} onChange={(key) => {
                    history.push(key)
                }}>
                    {itemChildren}
                </TabBar>

            }
        </div>


    </>
}

