import {Toast} from "antd-mobile";
import React, {useEffect, useState} from 'react'
import {ToastHandler} from "antd-mobile/es/components/toast/methods";

type Tips = {
    success: (text: string, close?: () => void) => ToastHandler;
    awaitSuccess: (text: string, close?: () => void) => void;
    error: (text: string, maskClickable?: boolean, close?: () => void) => ToastHandler;
    asyncError: (text: string, maskClickable?: boolean, close?: () => void) => void;
    loading: (options?: { text?: string, time?: number, close?: () => void }) => ToastHandler;
    loadingTime: (time: number, fallback?: () => void) => ToastHandler;
    clear: (fallback?: () => void) => void;
    awaitClose: (close: ToastHandler, fallback?: () => void) => void;
}
const tips: Tips = {
    success: (text: string, close?: () => void): ToastHandler => {
        return Toast.show({
            content: text,
            afterClose: close,
        })
    }, awaitSuccess: (text: string, close?: () => void): void => {
        setTimeout(() => {
            Toast.show({
                content: text,
                afterClose: close,
            })
        }, 200)
    },
    error: (text: string, maskClickable?: boolean, close?: () => void): ToastHandler => {
        return Toast.show({
            maskClickable: maskClickable,
            content: text,
            afterClose: close,
        })
    }, asyncError: (text: string, maskClickable?: boolean, close?: () => void) => {
        setTimeout(() => {
            Toast.show({
                maskClickable: maskClickable,
                content: text,
                afterClose: close,
            })
        }, 200)
    },
    loading: (options?: { text?: string, time?: number, close?: () => void }): ToastHandler => {
        return Toast.show({
            maskClickable: false,
            icon: 'loading',
            afterClose: options?.close,
            content: options?.text || '处理中…',
            duration: options?.time || 60000,
        })
    }, loadingTime: (time: number, fallback?: () => void): ToastHandler => {
        return Toast.show({
            maskClickable: false,
            icon: 'loading',
            content: <CountDownText time={time} fallback={fallback}/>,
            duration: time * 1000,
        })
    }, clear: (fallback?: () => void) => {
        if (fallback) {
            setTimeout(() => {
                Toast.clear()
                fallback()
            }, 500)
        } else {
            Toast.clear()
        }

    }, awaitClose(handler: ToastHandler, fallback?: () => void) {
        if (fallback) {
            setTimeout(() => {
                handler.close()
                fallback()
            }, 500)
        } else {
            handler.close()
        }
    }
}

const CountDownText = (props: { time: number, fallback?: () => void }) => {
    const {time, fallback} = props;
    const [count, setCount] = useState(0)
    useEffect(() => {
        const interval = window.setInterval(() => {
            setCount(x => {
                if (x < time) {
                    return x + 1
                } else {
                    fallback?.()
                    return x
                }
            })
        }, 1000)
        return () => {
            window.clearInterval(interval)
        }
    }, [time, fallback])
    return <span>预计剩余 {time - count} 秒</span>
}

export default tips;
