import MobileDetect from "mobile-detect";

export class AppMobileDetect {
    detect: MobileDetect;

    constructor() {
        this.detect = new MobileDetect(navigator.userAgent)
    }

    isIPhone(): boolean {
        return this.detect.is('iPhone')
    }

    isAndroid(): boolean {
        return this.detect.is('AndroidOS')
    }

    isWechat(): boolean {
        return this.detect.match(/MicroMessenger/i)
    }

    // 0 未知 1 安卓 2 苹果 3 网页 4 微信小程序 5 其他
    osType(): number {
        if (this.isAndroid()) {
            return 1;
        }
        if (this.isIPhone()) {
            return 2;
        }
        if (this.isBrowser()) {
            return 3;
        }
        return 0;
    }

    isBrowser(): boolean {
        return this.detect.is('Browser')
    }

    isApp(): boolean {
        const list = navigator.userAgent.split('///');
        return list.length > 1;
    }

    os(): { osName: string, osId: string } {
        return {
            osName: (this.isAndroid() ? "Android" : this.isIPhone() ? "Ios" : "Know") + "_" + (this.isWechat() ? "WeChat" : this.isApp() ? "App" : "Browser"),
            osId: "know"
        }
    }
}

const AppDetect = new AppMobileDetect()
export default AppDetect;
