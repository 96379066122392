import {MenuElement} from "./router-parse";
import React, {useEffect} from "react";
import AdminProgress from "../common/progress";
import ITabPage from "../layout/ITabPage";
import Global from "./global";
import LoginUser from "../store/loginUser";
import {OpenWeb501, OpenWebLogin} from "@/common/utils";

export function LoaderPage(element: MenuElement): React.ComponentType {
    const children = (props: any) => {
        CheckAuth(element)
        const Comp = React.lazy(() => import(`../${element.element}`));
        return <React.Suspense fallback={<>   <AdminProgress/></>}>
            <Comp {...props} element={element}/>

        </React.Suspense>
    }
    return children
}

export function NonePage(element: MenuElement): React.ComponentType {
    const children = (props: any) => {
        CheckAuth(element)
        const Comp = React.lazy(() => import(`../${element.element}`));
        return <React.Suspense fallback={<>   <AdminProgress/></>}>
            <Comp {...props} element={element}/>
        </React.Suspense>
    }
    return children
};

export function LoaderLayoutPage(element: MenuElement): React.ComponentType {
    const children = (props: any) => {
        CheckAuth(element)
        const Comp = React.lazy(() => import(`../${element.element}`));
        return <React.Suspense fallback={<><AdminProgress/></>}>
            <ITabPage {...props} element={element}>
                <Comp  {...props} element={element}/>
            </ITabPage>
        </React.Suspense>
    }
    return children
};


export function CheckAuth(element: MenuElement) {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let token = params.get("token");
        let osType = params.get("os"); //  F1 IOS F2 安卓
        let mobile = params.get("mobile");
        if (token && osType && mobile) {
            LoginUser.saveAndFlush({
                token, osType, mobile
            })
        }
        if (element && Global.CheckAuth) {
            if (element && !element.unLogin) {
                if (!LoginUser.token) {
                    if (LoginUser.channelToken || LoginUser.channelCode) {
                        OpenWebLogin()
                    } else {
                        OpenWeb501()
                    }
                }

            }
        }
    }, [element, window.location.search])

}
