import React from 'react';
import './App.less';
import RouterApp from "./config/router-app";

function App() {

    return (
        <React.Suspense>
            <RouterApp/>
        </React.Suspense>


    );
}

export default App;
