import React, {useEffect} from 'react';
// @ts-ignore
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


export default function AdminProgress() {
    useEffect(() => {
        NProgress.start()
    }, [])
    useEffect(() => {
        NProgress.done()
    })
    return <React.Fragment/>
}
